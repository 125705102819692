<template>
  <div>
<!--    <v-row class="hidden-md-and-up" style="height:75px">-->
<!--      <v-col cols="12" class="voucher-mobile-header text-center">-->
<!--        {{ $t(`label.claimVoucher`) }}-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-card class="desktop_card">
      <v-card-title class="hidden-sm-and-down">
        <label class="--v-primary-base text-left text-capitalize mr-4">{{ $t(`label.claimVoucher`) }}</label>
      </v-card-title>
      <v-card class="bank-detail-card voucher-card" elevation="0">
        <v-row no-gutters align="end" class="px-8 pa-4">
           <v-col lg=4 md=5>
            <v-form ref="codeForm">
              <app-form-field v-model.trim="voucherObj.code" :enabledLabel=true :label="$t(`label.applyVoucher`)" :placeholder="$t(`label.voucherCode`)" :rules="validator.requiredRules(4, 20)"></app-form-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters align="end" class="px-8 pa-4">
           <v-col lg=4 md=5>
            <app-button class="dialog-button submit-btn" :action="this.onClaimVoucherClick" :readonly="!codeIsValid" :title="$t(`button.submit`)"></app-button>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
    <!-- <app-dialog :dialogShow="msgDialog.show" :max-width="1100" :title="msgDialog.title" :closeAction="this.onCloseClick">
      <v-container :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' :'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' :'fill-height']">
        <div v-html="msgDialog.msg" class="cms_bonus_content"></div>
        <v-btn class="full-width font-weight-bold pa-2 buttonPrimary primary-button theme--light" @click="onCloseClick()">{{ $t(`label.ok`) }}
        </v-btn>
      </v-container>
    </app-dialog> -->
    <app-dialog :dialogShow="msgDialog.show" ref="claimVoucherDetailDialog" :max-width="400" :title="voucherObj.code" :header_title="'editProfile'"
                :closeAction="this.onCloseClick">
       <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' :'px-5'">
          <v-col cols="6">
            <label class="voucher-claim-dialog-title"> {{ $t(`field.bonusAmount`) }}</label>
          </v-col>
         <v-col cols=6 class="text-right" v-if="voucherCheck.data.bonusIsPercentage">
           {{voucherCheck.data.bonusPercentage}}
         </v-col>
          <v-col cols=6 class="text-right" v-else>
            {{voucherCheck.data.bonusGivenAmount}}
          </v-col>
          <v-col cols="6" class="mt-2 mb-6">
            <label class="voucher-claim-dialog-title"> {{ $t(`field.targetTurnover`) }}</label>
          </v-col>
          <v-col cols=6 class="text-right mt-2 mb-6">
            {{voucherCheck.data.bonusTargetRollover}}
          </v-col>
          <v-col cols=8>
            <v-btn height="auto" @click="applyVoucher" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">{{ $t(`label.confirm`) }}
              </v-btn>
          </v-col>
       </v-row>
    </app-dialog>
  </div>
</template>

<script>
  import AppGameCard from "../../components/home/GameCard";
  import {
    formValidator,
    locale,
    errorCodeHelper
  } from '@/util'
  import {
    VOUCHER_BONUS_APPLY,
    VOUCHER_BONUS_APPLY_RESET,
    VOUCHER_BONUS_CHECK,
    VOUCHER_BONUS_CHECK_RESET
  } from "../../store/bonus.module";
  export default {
    name: "claimVoucher",
    components: {
      AppGameCard
    },
    data: () => ({
      validator: formValidator,
      voucherObj: {
        code: ''
      },
      msgDialog: {
        show: false,
        title: '',
        msg: ''
      },
      codeIsValid: false,
      voucherCheck: {
        data: {}
      }
    }),
    computed: {
      voucherCheckResult() {
        return this.$store.state.bonus.checkVoucherResponse.complete
      },
      voucherApplyResult() {
        return this.$store.state.bonus.applyVoucherResponse.complete
      },
      voucherCode() {
        return this.voucherObj.code
      }
    },
    watch: {
      voucherApplyResult() {
        if (this.$store.state.bonus.applyVoucherResponse.complete) {
          this.openResultDialog(this.$store.state.bonus.applyVoucherResponse)
        }
      },
      voucherCheckResult() {
        if (this.$store.state.bonus.checkVoucherResponse.complete) {
          this.voucherCheck = this.$store.state.bonus.checkVoucherResponse
          this.openConfirmDialog(this.voucherCheck)
        }
      }
    },
    methods: {
      onClaimVoucherClick() {
        if(this.$refs.codeForm.validate()){
          const voucherObj = this.voucherObj
          this.$store.dispatch(`${VOUCHER_BONUS_CHECK}`, {
            voucherObj
          })
        }
      },
      openResultDialog(result) {
         if (result.code != 0 && !result.success) {
              this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(result.code))
          } else {
              this.$parent.openAlert(true, locale.getMessage(`message.voucherBonusApplied`))
          }
          this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
          this.$store.dispatch(`${VOUCHER_BONUS_APPLY_RESET}`)
      },
      openConfirmDialog(result) {
        if (result.code != 0 && !result.success) {
          this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(result.code))
          this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
        } else {
          this.msgDialog.show = true
        }
      },
      applyVoucher() {
        const voucherObj = this.voucherObj
        this.$store.dispatch(`${VOUCHER_BONUS_APPLY}`, {
          voucherObj
        })
        this.msgDialog.show = false
      },
      onCloseClick() {
        this.$store.dispatch(`${VOUCHER_BONUS_CHECK_RESET}`)
        this.msgDialog.show = false
      }
    }
  }
</script>

<style lang="scss">
.voucher-mobile-header{
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
  margin-top: auto;
  margin-bottom: auto;

}
.voucher-card {
    height: 80%;
}
.voucher-claim-dialog-title {
  color: #ACACAC
}

@media (max-width:959px){
  .submit-btn.buttonPrimary{
    background-color: var(--v-newMobilePrimary-base) !important;
    color:#FFFFFF;
  }
}
</style>
